
import * as React from "react"

import Layout from "../components/layout"
import { NAVBAR_PADDING } from "../components/navbar"


const NotFoundPage = () => {
  return (
    <Layout>
      <section className={`${NAVBAR_PADDING} flex flex-col justify-center`} style={{ minHeight: "100vh" }}>
        <div className="mx-auto">
          <div className="px-8 py-16">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              Page Not Found
            </h1>
            <p>
              Oops, the page you are looking for has been removed or is not yet created.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage